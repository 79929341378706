@use 'globals/styles/_mixins.scss' as *;

.about {
  display: flex;
  align-items: center;
  gap: 6rem;

  .about-images-container {
    position: relative;
    flex: 0 0 24rem;
    height: 22rem;

    .image-1,
    .image-2 {
      width: 13.75rem;
      height: 13.75rem;
      object-fit: cover;
      position: absolute;
      border-radius: 1rem;
    }

    .image-1 {
      bottom: 0;
    }

    .image-2 {
      right: 0;
    }
  }

  .about-info {
    display: flex;
    flex: 1;
    flex-direction: column;

    .about-personal-info {
      margin-bottom: 1rem;

      .about-data {
        h4 {
          margin-bottom: 0.25rem;
        }

        a {
          font-weight: 700;
        }
      }
    }
  }
}

@include screen-md {
  .about {
    gap: 3rem;
    flex-direction: column;

    .about-images-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      height: unset;
      flex: 1;
      gap: 2rem;

      .image-1,
      .image-2 {
        position: relative;
        width: calc(50% - 1rem);
        height: auto;
      }
    }
  }
}

@include screen-xs {
  .about {
    .about-images-container {
      flex-direction: column;

      .image-1,
      .image-2 {
        height: auto;
        width: 100%;
      }
    }
  }
}
