$font-family-name: 'Titillium Web';

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: $font-family-name;
  src: url('/assets/fonts/TitilliumWeb-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}