@use 'globals/styles/_mixins.scss' as *;

.tech-stack {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  column-gap: 0.15rem;

  h4 {
    flex-basis: 100%;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .icon-container {
    width: 3rem;
    height: 3rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }
}

@include screen-md {
  .tech-stack {
    justify-content: center;
    margin-top: 1.5rem;

    h4 {
      text-align: center;
    }
  }
}