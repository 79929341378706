@use 'globals/styles/_mixins.scss' as *;

* {
  transition: transform 50ms ease-in-out;
}

section {
  padding: 5rem 0rem;
}

.section-home {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 4.15rem);
  background-color: #2c3d55;
  margin-top: 4.15rem;
  background-image: url('/assets/main-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
  }

  > * {
    z-index: 1;
  }
}

.section-about,
.section-contact {
  background-color: #f0f0f0;
}

.theme-dark {
  &,
  a {
    color: #ffffff;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

a {
  color: #175bec;
}

@include screen-md {
  section,
  section.section-home {
    padding: 2rem 0rem;
  }
}

@include screen-xs {
  .btn {
    width: 100%;
  }
}
