@use 'globals/styles/_mixins.scss' as *;

.projects {
  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .project-card {
      display: flex;
      gap: 2rem;
      justify-content: space-between;

      &:nth-child(4n + 1) {
        flex-direction: row-reverse;
      }

      .project-image {
        color: #ffffff;
        width: 45%;
        height: auto;
        background-color: #2c3d55;
        border: 0.1rem solid #181716;
        border-radius: 1rem;
        aspect-ratio: 1.78;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .project-info {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        .links-container {
          display: flex;
          align-items: center;
          gap: 2rem;
          margin-top: auto;
        }
      }
    }
  }
}

@include screen-lg {
  .projects {
    .projects-container {
      .project-card {
        &,
        &:nth-child(4n + 1) {
          flex-direction: column;
        }

        .project-info {
          padding: 0;
        }

        .project-image {
          width: 100%;
        }
      }
    }
  }
}

@include screen-xs {
  .projects {
    .projects-container {
      .project-card {
        .project-info {
          .links-container {
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
    }
  }
}
