@use 'globals/styles/_mixins.scss' as *;

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .form-group {
    display: flex;
    gap: 1.5rem;
  }

  .form-control {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: .25rem;

    label {
      cursor: pointer;
      width: max-content;
    }

    input,
    textarea {
      font-size: 1rem;
      line-height: 1.5;
      color: #212529;
      background-color: #FFFFFF;
      border: 0.0625rem solid #CED4DA;
      border-radius: 0.25rem;
      padding: 0.75rem 1.125rem;
      transition: border-color .15s ease-in-out;

      &:focus {
        outline: none;
        border-color: #40A2D8;
      }
    }

    textarea {
      resize: vertical;
      min-height: 10rem;
      max-height: 18rem;
    }
  }

  .submit-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 700;

    span {
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s linear;

      &.animate {
        visibility: visible;
        opacity: 1;
      }

      &.success {
        color: #198754;
      }
  
      &.error {
        color: #DC3545;
      }
    }
  }
}

@include screen-sm {
  .contact-form {
    .form-group {
      flex-direction: column;
    }

    .form-control {
      input,
      textarea {
        padding: 0.5rem;
      }
    }
  }
}

@include screen-xs {
  .contact-form {
    .submit-container {
      flex-direction: column;
      justify-content: center;
    }
  }
}