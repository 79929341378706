.btn {
  background: #0c1a3c;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.025rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 0.8rem 1.25rem;
  position: relative;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  width: max-content;
  min-width: 11.25rem;

  &:not(:disabled) {
    &:hover {
      transform: scale(1.05);
    }

    &:focus {
      outline: 0 solid transparent;
    }

    &:focus:before {
      border-width: 0.125rem;
      content: '';
      left: calc(-1 * 0.375rem);
      pointer-events: none;
      position: absolute;
      top: calc(-1 * 0.375rem);
      transition: border-radius;
      user-select: none;
    }

    &:active {
      transform: translateY(0.125rem);
    }
  }

  &:disabled {
    background-color: #14285c;
  }

  svg {
    height: 1.35rem;
  }
}
