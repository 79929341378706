@use 'globals/styles/_mixins.scss' as *;

.hero-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .columns-group {
    display: flex;
    gap: 6.25rem;
    flex: 1;

    .column-info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 3rem;
        line-height: 0.9;
        letter-spacing: 0.2rem;
        font-weight: 900;

        span {
          display: block;
        }
      }

      .text {
        font-size: 1.3rem;
        line-height: 1.1;
      }
    }

    .column-image {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 18.75rem;
      width: 18.75rem;

      &::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;
        border: 0.15rem solid #FFFFFF;
      }

      .image-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 0.5rem;

        img {
          height: 100%;
          object-position: -0.5625rem;
        }
      }
    }
  }
}

@include screen-md {
  .hero-main {
    justify-content: center;

    .columns-group {
      flex-direction: column-reverse;
      align-items: center;
      gap: 4rem;

      .column-info {
        text-align: center;

        .title {
          font-size: 2rem;
        }
      }

      .column-image {
        height: 11.75rem;
        width: 11.75rem;
      }
    }
  }
}