.tooltip {
  position: relative;

  .tooltip-text {
    width: max-content;
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.25rem 0.625rem;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;

    &:before {
      content: "";
      position: absolute;
      top: -0.6rem;
      left: 50%;
      margin-left: -0.35rem; /* Adjust based on the width of the arrow */
      border-width: 0.35rem;
      border-style: solid;
      border-color: transparent transparent #000000 transparent;
    }
  }
}