.social-media {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.625rem;

  a {
    display: inline-flex;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;

    &:hover {
      transform: scale(1.2);
    }
  }
}
