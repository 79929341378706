@use 'globals/styles/_mixins.scss' as *;
@use 'globals/styles/_fonts.scss' as *;

* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  font-size: 20px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  box-sizing: border-box;

  &,
  textarea,
  input[type=text] {
    font-family: $font-family-name, sans-serif;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.25rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }
}

@include screen-md {
  html {
    font-size: 18px;
  }
}